import React from "react";
import { useTranslation } from "react-i18next";
import {
  AppsWrapper,
  Footer,
  Header,
  ItemsWrapper,
  LoginButton,
  LogoImg,
} from "./styled";

import logo from "../../assets/brand/cbimage.png";
import logoAlive from "../../assets/brand/Logo_AnT.png";
import MenuLanguage from "../menuLanguage";

const AppsPage = () => {
  const { t } = useTranslation();

  return (
    <AppsWrapper>
      <Header>
        <LogoImg className="logo" src={logo} alt="logo-Bo-Y-Te" />
        <MenuLanguage />
      </Header>

      <ItemsWrapper>
        <div
          className="item ncbsm"
          onClick={() => {
            setTimeout(() => {
              window.open("https://ncbsm.bmte.vn/", "_blank");
            }, 0);
          }}
        >
          <div className="overlay" />
          <div className="title">{t("app.breastfeeding")}</div>
          <LoginButton>{t("common.login")}</LoginButton>
        </div>

        <div
          className="item nhsm"
          onClick={() => {
            setTimeout(() => {
              window.open("https://nhsm.bmte.vn", "_blank");
            }, 0);
          }}
        >
          <div className="overlay" />
          <div className="title">{t("app.breastMilkBank")}</div>
          <LoginButton>{t("common.login")}</LoginButton>
        </div>

        <div
          className="item dtts"
          onClick={() => {
            setTimeout(() => {
              window.open("https://dtts.bmte.vn/", "_blank");
            }, 0);
          }}
        >
          <div className="overlay" />
          <div className="title">{t("app.nationalMinorities")}</div>
          <LoginButton
            onClick={(e) => {
              e.stopPropagation();
              setTimeout(() => {
                window.open("https://dtts.bmte.vn/admin", "_blank");
              }, 0);
            }}
          >
            {t("common.login")}
          </LoginButton>
        </div>
      </ItemsWrapper>

      <div style={{ flex: 1 }} />

      <Footer>
        <div>© {t("app.copyright")}</div>

        <div style={{ width: "80px" }} />

        <div>{t("app.supportBuild")} </div>
        <img src={logoAlive} alt="" />

        <div style={{ width: "80px" }} />

        <div>{t("app.image")}: Alive & Thrive</div>
      </Footer>
    </AppsWrapper>
  );
};

export default AppsPage;
