import { Button } from "antd/lib";
import styled from "styled-components";
import background_ncbsm from "../../assets/brand/img_1.JPG";
import background_nhsm from "../../assets/brand/img_2.jpg";
import background_dtts from "../../assets/brand/img_3.jpeg";

export const IconLanguage = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;
export const ButtonLanguage = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 85px;
`;

export const LoginButton = styled.div`
  border-radius: 100px;
  border: 1px solid white;
  width: fit-content;
  padding: 3px 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  :hover {
    text-decoration: underline;
  }
  z-index: 2;
  cursor: pointer;
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    flex-direction: row; /* Example adjustment for large screens */
    justify-content: space-between; /* Adjust layout for large screens */
  }
`;

export const LogoImg = styled.img`
  width: 80px;
  height: 80px;
`;

export const AppsWrapper = styled.div`
  background-color: #f5fff9;
  min-height: 100vh;
  background-size: cover;
  display: flex;
  flex-direction: column;

  .item {
    position: relative;
    padding: 0px 64px;
    width: 100%;
    height: 200px;
    font-size: 1rem;
    color: white;
    box-shadow: 1px 2px 2px 1px #dfe6e9;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    gap: 16px;

    @media (min-width: 768px) {
      height: 500px;
    }
  }

  .title {
    z-index: 1;
    height: 45px;
  }

  .item > .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #000;
    opacity: 0.2;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }

  .item:hover > .overlay {
    opacity: 0.6;
  }

  .ncbsm {
    background-image: url(${background_ncbsm});
    background-position: 100%;
    background-size: cover;
  }

  .nhsm {
    background-image: url(${background_nhsm});
    background-size: cover;
    margin: 0 4px;
  }

  .dtts {
    background-image: url(${background_dtts});
    background-position: 40%;
    background-size: cover;
  }
`;

export const Header = styled.div`
  padding: 16px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Footer = styled.div`
  background-color: #ecf0f1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  img {
    width: auto;
    height: 40px;
  }
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;
